
import './App.css';
import Nabvar from './Components/Nav/Nav.jsx';
import CardsGames from './Components/CardsGames/CardsGames.jsx'; 
import About from './Components/About/About.jsx';
import Footer from './Components/Footer/Footer.jsx';
import Posts from './Components/Posts/Post.jsx';

function App() {

  return (
    <div className="App">
        <Nabvar/>
        <About/>
        <CardsGames className="scroll-container"/>
        <Posts/>
        <Footer/>
      

    </div>
  );
}

export default App;
  /*
            ███████╗███████╗████████╗ ██████╗ 
            ██╔════╝██╔════╝╚══██╔══╝██╔═══██╗
            ███████╗█████╗     ██║   ██║   ██║
            ╚════██║██╔══╝     ██║   ██║   ██║
            ███████║███████╗   ██║   ╚██████╔╝ 92
            ╚══════╝╚══════╝   ╚═╝    ╚═════╝ 
                   SETO - Pablo Marchán
                   
           
 */
