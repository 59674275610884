import React from "react";
import { Link } from "react-scroll";
import "./Nav.css";

const Navbar = () => {
  return (
    <div className="Divmain">
      <div className="ContentNav">
        <img className="logo" src="/images/SETO.png" alt="SETO" />
        <Link
          to="about"
          smooth={true}
          duration={500}
          className="btn"
          aria-label="Go to About Me section"
        >
          About me
        </Link>
        <Link
          to="Projects"
          smooth={true}
          duration={500}
          className="btn"
          aria-label="Go to Projects section"
        >
          Projects
        </Link>
        <Link
          to="post"
          smooth={true}
          duration={500}
          className="btn"
          aria-label="Go to Others section"
        >
          Others
        </Link>
      </div>
    
    </div>
  );
};

export default Navbar;
