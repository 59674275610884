import React, { useEffect, useState } from 'react';
import './Posts.css';
import { getDocs, collection } from 'firebase/firestore';
import { db } from "../../service"; // Importa correctamente tu instancia de Firebase

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Cambia la colección a 'Posts'
    getDocs(collection(db, 'Posts')).then((response) => {
      const postList = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(postList);
    });
  }, []);

  return (
    <div id="Posts">
      <h2 className='Textmain' >Recent Posts</h2>
      {posts.map((post) => (
        <div key={post.id} className="post">
          <h3>{post.title}</h3>
          <p>{post.content}</p>
          <small>Publicado el: {new Date(post.date).toLocaleDateString()}</small>
          {post.videoId && (
            <div className="video">
              <iframe
                width="900"
                height="500"
                src={`https://www.youtube.com/embed/${post.videoId}`}
                title={`Video relacionado a ${post.title}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Posts;

