import React from "react";
import './About.css'


const About = ()=>{

    return(
        <div className="MainAbout" id="about">

            <p>
            Welcome to my digital corner! My name is Pablo Marchán, and I'm from Uruguay. I love animals and am Christian. My true passion is technology; I love diving into the vast ocean of software development. My curiosity has led me not to limit myself to one area but to explore a wide range of technological disciplines. From web development to creating video games, through artificial intelligence, and digital art in 3D, 2D, and pixel art, where creativity meets technology. 😊

            Recently, I've started exploring literature, adding a new dimension to my creativity. However, programming remains my core, the place where ideas transform into digital realities, taking you to a parallel universe of possibilities. 🌐📚

            I invite you to browse around my website for a while. Have a great time! 🎉

    

            </p>



        </div>




    )





}

export default About;
