import React, { useEffect, useState } from 'react';
import './CardsGames.css'
import { getDocs, collection } from 'firebase/firestore';
import { db } from "../../service"; // Asegúrate de importar correctamente storage

const CardsGames = () => {
 
  const [Products, SetProducts] = useState([]);
  

  useEffect(() => {
    getDocs(collection(db, 'Proyectos')).then((response) => {
      const games = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      SetProducts(games);
    });
  }, []);





  return (
    <div id='Projects'>
      {
        // Solo un map para iterar sobre los productos
        Products.map((producto, index) => {
          return (
            <div key={producto.id} className="ContainerCard">
              <div className="Card">
                <img src={producto.IMG} alt={producto.NAME} />
                <div className="info">
                  <h1>{producto.NAME}</h1>
                  <p>{producto.DESCRIPTION}</p>
                  <a href={producto.LINK} target="_blank" rel="noopener noreferrer" className="card-button">
                  Website
                    </a>
                </div>
                
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default CardsGames;
